import thumbTV from '../../src/images/data/TV.png';
import thumbBB from '../../src/images/data/Broadband.png';
import thumbMB from '../../src/images/data/Mobile.png';
import thumbApps from '../../src/images/data/VIP.png';
import thumbVIP from '../../src/images/data/Apps.png';

import imgTV from '../../src/images/data/Sky_TV_Retail_App_Assets_IT_pg3_Desktop.png';
import imgBB from '../../src/images/data/Broadband_Retail_App_Assets_IT_pg4_Desktop.png';
import imgMB from '../../src/images/data/Mobile_Retail_App_Assets_IT_pg5_Desktop.png';
import imgApps from '../../src/images/data/VIP_Retail_App_Assets_IT__pg6_Desktop.png';
import imgVIP from '../../src/images/data/Sky_Apps_Retail_App_Assets_IT_pg7_Desktop.png';

const home = {
    data : {
        wp: {
            home: {
                title : 'Select a category',
                options : [
                    {
                        id: 'home-tv',
                        title : 'TV',
                        thumb : thumbTV,
                        img : imgTV,
                        link: '/tv'
                    },
                    {
                        id: 'home-broadband',
                        title : 'Broadband',
                        thumb : thumbBB,
                        img : imgBB,
                        link: '/broadband'
                    },
                    {
                        id: 'home-mobile',
                        title : 'Mobile',
                        thumb : thumbMB,
                        img : imgMB,
                        link: '/mobile'
                    },
                    {
                        id: 'home-apps',
                        title : 'Apps',
                        thumb : thumbVIP,
                        img : imgVIP,
                        link: '/apps'
                    },
                    {
                        id: 'home-vip',
                        title : 'VIP',
                        thumb : thumbApps,
                        img : imgApps,
                        link: '/vip'
                    },
                ]
            }
        },

    }
}

export default home;