import React from "react";
import Layout from '../UI/Layout';
import ThumbsOptions from "../components/ThumbsOptions";
import home from '../../queries/data/home';

const Index = () => {

  const data = {...home};

    return (
      <Layout>
          <ThumbsOptions data={data} />
      </Layout>
    )
}

export default Index;