import React,{useState, useEffect, useReducer}  from 'react';
import { navigate } from 'gatsby-link';
import useLS from '../hooks/useLS';
import img from '../../src/images/data/placeholder.png';
import shadow from '../../src/images/data/shadow.png';
import '../scss/home.scss';


const reducer = (state, action) => {
    if (state === action.max-1) {
        return 0
    }
    switch(action.type) {
      case "Increment":
        return state + 1;
      case "selectIt":
        return action.index;
      default:
        return state;
    }
  }

const ThumbsOptions = ({data}) => {
    
    const [selected, setSelected] = useState(0);
    const [state, dispatch] = useReducer(reducer, 0);

    let rotateInterval = {};

    useEffect(() => {
        rotateInterval = setInterval(() => {
            dispatch({type: "Increment", max: pageData.options.length});
        }, 3000);
    }, [])
    
    const LSData = useLS();
    const pageData = data?.data?.wp?.home;

    const onClickHandler = (index) => {
        clearInterval(rotateInterval);
        dispatch({type: "selectIt", index});
        setTimeout(() => {
            const link = pageData.options[index].link;
            typeof window !== 'undefined' && navigate(`${link}?storeID=${LSData.storeID}`);
        }, 500);
        // }, 1000);
    }

    

    return (
        <div className='ThumbsOptions'>
            <div className='mainImage' style={{backgroundImage:`url(${img})`, backgroundPosition:'center center', backgroundSize:'cover', height:'100vh'}}>
                {pageData.options.map((itm, index) => {
                    return <img key={index} className={state === index ? 'active' : ''} src={itm.img} />;
                })}
            </div>
            <div className='navigator'>
                    {pageData.options && !!pageData.options.length && (
                        <ul>
                            {pageData.options.map((itm, index) => {
                                return <li className={state === index ? 'active' : ''} onClick={() => onClickHandler(index)} key={'thumb-'+itm.id}>
                                        {
                                            !index &&   <h3 style={{color:'#989898'}}>
                                                {pageData && pageData.title}
                                            </h3>
                                        }
                                        <div style={{position:'relative'}}>
                                            <img src={itm.thumb} />
                                            <img src={shadow} style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} />
                                            <p>{itm.title}</p>
                                        </div>
                                    </li>;
                            })}
                        </ul>
                    )}
            </div>
        </div>
    )
}

export default ThumbsOptions;